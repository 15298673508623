/** @jsx jsx */
import { jsx, Global } from "@emotion/core"

export default function GlobalCSS() {
  return (
    <Global
      styles={`
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      color: #555555;
      background: #fafafa;
      font: normal 18px/1.75 Georgia, sans-serif;
    }

    pre {
      color: #009688;
      padding: 16px 32px;
      font-size: 14px;
      overflow-y: scroll;
      background: #fafafa;
      margin: .25rem 0 2.5rem;
      border: 1px #efefef solid;
      font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
      &:last-child {
        margin-bottom: 0;
      }
      margin-left: -33px;
      margin-right -33px;
    }

    code {
      color: #009688;
      font-size: .875em;
      font-style: italic;
    }

    h1, h2, h3, h4 {
      color: #333333;
      font-weight: normal;
      line-height: 1.125;
      margin: 1.25rem 0 .5rem;
      font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Arial, sans-serif;
    }

    h1 {
      margin-top: 0;
      line-height: 1.125;
      margin-bottom: 16px;
    }

    h4 {
      margin-top: 2rem;
    }

    p {
      margin: .25rem 0 .75rem;
    }

    a {
      color: #519657;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    label {
      display: block;
      margin-top: 16px;
      line-height: 1em;
      margin-bottom: 4px;
    }

    input, textarea {
      width: 100%;
      padding: 8px;
      color: #555555;
      font-size:16px;
      max-width: 300px;
      border-radius: 3px;
      border: 1px #ddd solid;
    }

    textarea {
      max-width: 100%
    }
  `}
    />
  )
}
