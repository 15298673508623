/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "gatsby"

export default function Navigation() {
  return (
    <nav
      css={{
        textAlign: "center",
        padding: 8,
        a: {
          marginLeft: 12,
          marginRight: 12,
          textDecoration: "none",
          "&.active, &:hover": { textDecoration: "underline" },
        },
      }}
    >
      <Link to="/" activeClassName="active">
        Notes
      </Link>
      &middot;
      <Link to="/projects/" activeClassName="active">
        Projects
      </Link>
      &middot;
      <Link to="/skills/" activeClassName="active">
        Skills
      </Link>
      &middot;
      <Link to="/contact/" activeClassName="active">
        Say Hi!
      </Link>
    </nav>
  )
}
