/** @jsx jsx */
import { jsx } from "@emotion/core"
import Helmet from "react-helmet"

import GlobalCSS from "./global-css"
import Navigation from "./navigation"
import Header from "./header"
import useSiteMetadata from "../hooks/use-site-metadata"

export default function Layout({ children }) {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <GlobalCSS />
      <Helmet>
        <html lang="pt-BR" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <Header />
      <Navigation />
      <main
        css={{
          margin: "0 auto",
          maxWidth: 782,
          padding: 16,
          paddingBottom: 64,
        }}
      >
        {children}
      </main>
    </div>
  )
}
