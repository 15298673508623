/** @jsx jsx */
import { jsx } from "@emotion/core"

export default function Card({ children, bgImageUrl }) {
  return (
    <article
      css={{
        padding: 32,
        borderRadius: 3,
        marginBottom: 16,
        border: "1px #ededed solid",
        backgroundSize: "33.3333%",
        backgroundColor: "#FFFFFF",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        backgroundImage: bgImageUrl ? `url(${bgImageUrl})` : "none",
      }}
    >
      {children}
    </article>
  )
}

export function CardMute({ children }) {
  return (
    <p
      css={{
        margin: 0,
        color: "#9a9a9a",
        fontSize: "0.85em",
      }}
    >
      {children}
    </p>
  )
}

export function CardTitle({ children }) {
  return (
    <h3 css={{ margin: 0, lineHeight: 1.25, marginBottom: 16 }}>{children}</h3>
  )
}
